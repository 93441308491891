<template>
  <div class="mb-3 card">
    <div>
      <!-- Table -->
      <Datatable v-show="showTable" ref="customerDatatable"
                 :api-endpoint="ENDPOINTS.DATATABLES.ONLINESHOP.CUSTOMERS"
                 :datatable-headers="datatableHeaders"
                 :excel-columns="excelColumns"
                 excel-file-name="Benutzer"
                 @editEntry="entryEdit"
                 @deleteEntry="entryDelete"
                 @deleteEntries="entriesDelete"
                 show-delete-buttons
                 show-edit-buttons
                 :permissionDelete="this.$store.getters['permissions/checkPermission']('delete_cust')"
                 :permissionEdit="this.$store.getters['permissions/checkPermission']('edit_cust')"
      />
    </div>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded" :options="touchKeyboard.options"
                          :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                          :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </div>
</template>

<script>
import {ENDPOINTS} from '../../config'
import {Events} from "../../plugins/events";
import Datatable from "../datatable/Datatable";
import mixin from "../../mixins/KeyboardMixIns";

export default {
  components: {
    Datatable
  },

  mixins: [mixin],

  data() {
    return {
      ENDPOINTS,
      id: null,
      text: "",
      allergenAdd: "",
      loading: false,
      showTable: true,
      showCreate: false,
      showUpdate: false,
      tab: 0,
      // --- Datatable ---
      datatableHeaders: [
        {
          text: 'ID',
          align: 'left',
          value: 'id',
          width: 80,
          hide: false
        },
        {
          text: 'CC',
          align: 'left',
          value: 'cc',
          width: 80,
          hide: true
        },
        {text: this.$t("generic.lang_company"), value: 'company'},
        {text: this.$t("customers.lang_cust_prename"), value: 'prename',  searchable: true, searchCol: 6},
        {text: this.$t("customers.lang_cust_name"), value: 'lastname',  searchable: true, searchCol: 6},
        {text: this.$t("generic.lang_street"), value: 'street'},
        {text: this.$t("customers.lang_cust_zip"), value: 'zip'},
        {text: this.$t("settings.lang_city"), value: 'city'},
        {text: this.$t("customers.lang_cust_birth"), value: 'birth'},
        {text: this.$t("generic.lang_emailAdresse"), value: 'email'},
        {text: this.$t("customers.lang_custGroup"), value: 'group',hide: true},
      ],
      excelColumns: [

        {
          label: "ID",
          field: "id",
        },
        {
          label: "CC",
          field: "cc",
        },
        {
          label: this.$t("generic.lang_company"),
          field: "company",
        },
        {
          label: this.$t('customers.lang_custGroup'),
          field: "group",
        },
        {
          label: this.$t("customers.lang_cust_prename"),
          field: "prename",
        },
        {
          label: this.$t("customers.lang_cust_name"),
          field: "lastname",
        },
        {
          label: this.$t("generic.lang_street"),
          field: "street",
        },
        {
          label: this.$t("customers.lang_cust_zip"),
          field: "zip",
        },
        {
          label: this.$t("settings.lang_city"),
          field: "city",
        },
        {
          label: this.$t("customers.lang_cust_birth"),
          field: "birth",
        },
        {
          label: this.$t("generic.lang_emailAdresse"),
          field: "email",
        },
      ],
    }
  },

  watch: {
    tab: function () {
      if (this.tab === 1) {
        this.showTable = true;
        this.showUpdate = false;
      }
    }
  },

  methods: {
    deleteData: function (idsToDelete = []) {
      this.$swal({
        title: this.$t('customer.lang_customerDeleteHead'),
        text: this.$t('customer.lang_customerDeleteBody'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(parseInt(this.id));
          }
          this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.DELETE, {
            customerID: idsToDelete
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('customer.lang_customerDeleted'),
                color: "success"
              });

              this.resetData();

              this.$refs.customerDatatable.getDataFromApi();
              this.$refs.customerDatatable.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    resetData: function () {
      this.showCreate = false;
      this.showUpdate = false;
      this.showTable = true;
      this.id = null;
      this.text = "";
    },
    showList: function () {
      this.resetData();
      this.tab = 0;
    },
    entryEdit(entry) {
      this.id = entry.id;
      this.text = entry.name;

      this.$router.push({name: 'customer.editCustomer', params: {id: parseInt(this.id)}})
    },
    entryDelete(entry) {
      this.id = entry.id;
      this.deleteData();
    },
    entriesDelete(entries) {
      this.deleteData(entries);
    }
  },
}
</script>