<template>
  <div>
    <page-title :heading="$t('customers.lang_customers')" :subheading="$t('customers.lang_customers')" :icon=icon></page-title>
    <div class="app-main__inner">
      <OnlineShopCustomersComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../Layout/Components/PageTitle.vue";
import OnlineShopCustomersComponent from "../../components/onlineshop/OnlineShopCustomersComponent";

export default {
  components: {
    OnlineShopCustomersComponent,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-shopbag icon-gradient bg-tempting-azure',
  })
}
</script>